import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import message from "@/components/CustomMessage";

import api, { getRedirectDomainUrl } from "@/api";
import type { ITeamInfoObj } from "@/api/interface";
import type { AuthContextType, IAuthResponse, IJoinTeamInfo } from "./interface";

import { addRedirectParam, getQueryValueByKeys, redirectParams } from "@/utils";
import Log from "@/utils/Log";
import constants, { routes } from "@/constants";

const initialJoinTeamInfo = {
  email: "",
  code: "",
  teamId: "",
  teamName: "",
};

const AuthContext = React.createContext<AuthContextType>(null!);

export const useRedirectNavigation = () => {
  const navigate = useNavigate();
  const queryParams = redirectParams();

  const getRedirectPath = (path: string) => {
    return `${path}?${queryParams.toString()}`;
  };

  const handleNavigate = (path: string) => {
    const redirectPath = getRedirectPath(path);
    navigate(redirectPath);
  };

  return handleNavigate;
};

function getCaptchaToken(): string {
  const { turnstile } = window;

  if (!turnstile) {
    console.error("Turnstile is not initialized on window.");
    return "";
  }

  try {
    return turnstile.getResponse();
  } catch (error) {
    console.error("Error while getting the Turnstile response:", error);
    return "";
  }
}

function resetCaptchaToken() {
  const { turnstile } = window;

  if (!turnstile) {
    console.error("Turnstile is not initialized on window.");
    return;
  }

  try {
    turnstile.reset();
  } catch (error) {
    console.error("Error while resetting the Turnstile:", error);
  }
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const isLoginPage = window.location.pathname === routes.login;
  const signInFrom: SignInApps = useMemo(() => {
    try {
      const signInAPP =
        getQueryValueByKeys(constants.REDIRECT_FROM) ||
        sessionStorage.getItem(constants.REDIRECT_FROM) ||
        constants.DEFAULT_SIGN_IN_APP;
      return signInAPP;
    } catch (e) {
      Log.error("Failed to access sign in App:", e);
    }
  }, [location.search]);
  Log.debug("signInFrom: ", signInFrom);

  const redirectUrl = useMemo(
    () =>
      getQueryValueByKeys(constants.REDIRECT_TO) ||
      sessionStorage.getItem(constants.REDIRECT_TO) ||
      getRedirectDomainUrl(signInFrom),
    [signInFrom, location.search]
  );

  const isAdminRole = (role?: string) => role && (role === "admin" || role === "owner");

  const [isLoading, setLoading] = useState(isLoginPage);
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [invalidTypes, setInvalidTypes] = useState<string>("");
  const [SSOUrl, setSSOUrl] = useState<string>("");
  const [checkCode, setCheckCode] = useState<string>("");
  const [teamInfoList, setTeamInfoList] = useState<ITeamInfoObj[]>([]);
  const [joinTeamInfo, setJoinTeamInfo] = useState<IJoinTeamInfo>(initialJoinTeamInfo);

  const navigate = useRedirectNavigation();

  const redirectToAppWithAuthParams = ({
    teamId,
    redirectApp,
    noValidAccessToken,
  }: {
    teamId?: string;
    redirectApp?: SignInApps;
    noValidAccessToken?: boolean;
  }) => {
    const accessToken = noValidAccessToken
      ? "no_valid_token"
      : localStorage.getItem(constants.ACCESS_TOKEN) || "";
    const redirectAppURL = redirectApp ? getRedirectDomainUrl(redirectApp) : redirectUrl;
    const redirectAddress = addRedirectParam(redirectAppURL, teamId, accessToken);
    Log.debug("loginSuccessRedirect redirectAddress: ", redirectAddress);

    sessionStorage.removeItem(constants.REDIRECT_FROM);
    sessionStorage.removeItem(constants.REDIRECT_TO);
    window.location.replace(redirectAddress);
  };

  const signInWithEmail = (email: string, isResend?: boolean) => {
    return new Promise<boolean>((resolve) => {
      setEmail(email);
      if (!isResend) {
        setLoading(true);
      }
      let hasEmailSentSuccess = false;
      api
        .requestLoginWithEmail(email, getCaptchaToken())
        .then((res) => {
          if (!res) {
            Log.debug("requestLoginWithEmail no response");
            return;
          }
          Log.debug("requestLoginWithEmail res: ", res);
          if (res?.sso_url) {
            const ssoFromUrl = `${window.location.origin}?${redirectParams().toString()}`;
            const ssoRedirectUrl = `${res.sso_url}?from=${ssoFromUrl}`;
            Log.debug("ssoRedirectUrl: ", ssoRedirectUrl);
            setSSOUrl(ssoRedirectUrl);
            navigate(routes.ssoCheck);
          }
          if (res?.check_code) {
            setCheckCode(res.check_code);
            sessionStorage.setItem(constants.EMAIL_KEY, email);
            sessionStorage.setItem(constants.CHECK_CODE, res.check_code);
            navigate(routes.emailConfirmCheck);
          }
          hasEmailSentSuccess = true;
        })
        .catch((err) => {
          Log.error("requestLoginWithEmail err: ", err);
        })
        .finally(() => {
          setLoading(false);
          resetCaptchaToken();
          resolve(hasEmailSentSuccess);
        });
    });
  };

  const authWithToken = (token: string) =>
    new Promise<IAuthResponse>((resolve) => {
      let authRes: IAuthResponse;
      api
        .authUserWithToken(token)
        .then((res) => {
          if (!res?.csrf_token) {
            Log.debug("csrf_token dosen't exist");
            return;
          }
          if (res?.user) {
            const user = res.user;
            user?.email && setEmail(user.email);
            user?.display_name && setUsername(user.display_name);
          }
          sessionStorage.setItem(constants.X_CSRF_TOKEN, res.csrf_token);
          localStorage.setItem(constants.ACCESS_TOKEN, token);
          authRes = res;
        })
        .catch((err) => {
          localStorage.removeItem(constants.ACCESS_TOKEN);
          Log.error("authUser err: ", err);
        })
        .finally(() => {
          resolve(authRes);
        });
    });

  const joinTeamWithTeamId = (joinWorkspaceInfo: IJoinTeamInfo) =>
    new Promise<boolean>((resolve) => {
      let joinSuccess = false;
      const { code, teamName, teamId } = joinWorkspaceInfo;
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "joinTeamError");
      api
        .joinTeamWithId(teamId, code)
        .then((res) => {
          Log.debug("join Team With Id res: ", res);
          joinSuccess = true;
          const isAdmin = isAdminRole(res?.role);
          message.success(
            `You have successfully joined team "${teamName}" as ${
              isAdmin ? "an Admin" : "a Member"
            }.`
          );
          sessionStorage.removeItem(constants.JOIN_TEAM_INFO);
          setJoinTeamInfo(initialJoinTeamInfo);
          const { admin, one } = constants.SIGN_IN_APP_NAMES;
          const redirectApp = isAdmin ? admin : one;
          redirectToAppWithAuthParams({ teamId, redirectApp });
        })
        .catch((err) => {
          Log.error("join Team With Id res err: ", err);
          navigate(routes.joinTeamError);
        })
        .finally(() => {
          resolve(joinSuccess);
        });
    });

  const signWithWorkspaceSelection = () =>
    new Promise<boolean>((resolve) => {
      const lastSelectedWorkspaceId = localStorage.getItem(constants.TEAM_ID);
      const queryWorkspaceId = getQueryValueByKeys(constants.TEAM_ID);
      Log.debug("lastSelectedWorkspaceId: ", lastSelectedWorkspaceId);
      api
        .getTeamsInfo()
        .then((teamInfoList) => {
          teamInfoList = teamInfoList || [];
          Log.debug("getTeamsInfo res teamInfoList: ", teamInfoList);
          if (teamInfoList?.length === 0) {
            // localStorage.removeItem(constants.TEAM_ID);
            setLoading(false);
            navigate(routes.noWorkspaceLoading);
            return;
          }
          if (signInFrom === "admin") {
            if (teamInfoList?.length === 1 && teamInfoList[0]?.personal) {
              setInvalidTypes(constants.NO_AVAILABLE_WORKSPACE);
              setLoading(false);
              navigate(routes.login);
              return;
            }
            teamInfoList =
              teamInfoList.filter((team) => {
                const isTeamWorkspace = /team./.test(team.teamId);
                return isTeamWorkspace && isAdminRole(team.role);
              }) || [];
            if (teamInfoList.length === 0) {
              setInvalidTypes(constants.INVALID_ACCOUNT);
              setLoading(false);
              navigate(routes.login);
              return;
            }
          }
          if (queryWorkspaceId && teamInfoList.find((team) => team.teamId === queryWorkspaceId)) {
            Log.debug("use query assigned workspace", queryWorkspaceId);
            redirectToAppWithAuthParams({ teamId: queryWorkspaceId });
            return;
          }
          if (
            lastSelectedWorkspaceId &&
            teamInfoList.find((team) => team.teamId === lastSelectedWorkspaceId)
          ) {
            Log.debug("last selected workspace", lastSelectedWorkspaceId);
            redirectToAppWithAuthParams({});
            return;
          }
          if (teamInfoList.length === 1) {
            Log.debug("only one available workspace: ", teamInfoList[0].teamId);
            redirectToAppWithAuthParams({ teamId: teamInfoList[0].teamId });
            return;
          }
          Log.debug("selection teamInfoList: ", teamInfoList);
          teamInfoList.map((team) => {
            const teamInfo = team;
            if (teamInfo.personal) {
              teamInfo.displayName = "Personal Space";
            }
            return teamInfo;
          });
          setTeamInfoList(teamInfoList);
          navigate(routes.selectWorkspace);
          return;
        })
        .catch((err) => {
          Log.error("signWithWorkspaceSelection err: ", err);
          navigate(routes.unknownError);
        })
        .finally(() => {
          setLoading(false);
          resolve(true);
        });
    });

  const authUser = (token?: string) => {
    return new Promise<boolean>((resolve) => {
      Log.debug("authUser: 00000000000000");
      const accessToken = new URLSearchParams(location.search).get(constants.ACCESS_TOKEN) || token;
      if (!accessToken) {
        setLoading(false);
        Log.debug("accessToken dosen't exist");
        resolve(false);
        return;
      }
      authWithToken(accessToken).then((res) => {
        Log.debug("authWithToken res: ", res);
        if (!res?.csrf_token) {
          Log.debug("auth with token failed");
          resolve(false);
          return;
        }
        Log.debug("joinTeamInfo: ", joinTeamInfo);
        let joinTeamInfoRes = joinTeamInfo;
        if (!joinTeamInfoRes?.code) {
          const infos = sessionStorage.getItem(constants.JOIN_TEAM_INFO);
          const joinTeamInfoParse = infos && JSON.parse(infos);
          joinTeamInfoRes = joinTeamInfoParse;
        }
        Log.debug("remove join team info session storage");
        if (joinTeamInfoRes?.code && joinTeamInfoRes?.teamId) {
          joinTeamWithTeamId(joinTeamInfoRes);
          resolve(true);
          return;
        }
        signWithWorkspaceSelection();
        resolve(true);
      });
    });
  };

  const registerApproval = (username: string, checkCode: string, registerCode: string) => {
    return new Promise<boolean>((resolve) => {
      setUsername(username);
      setLoading(true);
      let isApproved = false;
      api
        .register(username, checkCode, registerCode)
        .then(() => {
          isApproved = true;
          Log.debug("register res");
        })
        .catch((err) => {
          Log.error("AuthProvider register err: ", err);
        })
        .finally(() => {
          resolve(isApproved);
          setLoading(false);
        });
    });
  };

  const emailApproval = (checkCode: string, registerCode: string, authCallback?: AuthVoidFun) => {
    let isAuth = false;
    setLoading(true);
    return api
      .emailApproval(checkCode, registerCode)
      .then(() => {
        isAuth = true;
        Log.debug("emailApproval res");
      })
      .catch((err) => {
        const errorMsg = "emailApproval reject err: " + err;
        Log.error(errorMsg);
      })
      .finally(() => {
        setLoading(false);
        if (authCallback) {
          authCallback(isAuth);
        }
      });
  };

  const emailTvApproval = (requestId: string, resolveKey: string, authCallback?: AuthVoidFun) => {
    let isAuth = false;
    setLoading(true);
    return api
      .resolveImpersonate(requestId, resolveKey)
      .then(() => {
        isAuth = true;
      })
      .catch((err) => {
        const errorMsg = "emailApproval reject err: " + err;
        Log.error(errorMsg);
      })
      .finally(() => {
        setLoading(false);
        if (authCallback) {
          authCallback(isAuth);
        }
      });
  };

  const createWorkspace = (value: string) =>
    new Promise<string>((resolve) => {
      setLoading(true);
      let teamId = "";
      api
        .updateWorkspaceName(value)
        .then((res) => {
          Log.debug("updateWorkspaceName success res:", res);
          teamId = res?.team_id || "";
          if (!teamId) {
            message.error("Failed to create a workspace. Please try again.");
            throw Error("Failed to create a workspace");
          }
        })
        .catch((err) => {
          setLoading(false);
          navigate(routes.login);
          Log.error("err: ", err);
        })
        .finally(() => {
          resolve(teamId);
          setLoading(false);
        });
    });

  const authWithLocalAccessToken = (isCheckToken: boolean) => {
    const localAccessToken =
      localStorage.getItem(constants.ACCESS_TOKEN) || getQueryValueByKeys(constants.ACCESS_TOKEN);
    if (localAccessToken) {
      Log.debug("login and auth with access_token: ", localAccessToken);
      sessionStorage.setItem(constants.HIDE_ERROR_MSG, "reauth");
      authUser(localAccessToken).then((isAuthed) => {
        if (!isAuthed) {
          Log.debug("auth With Local Access Token failed and redirect to login");
          localStorage.removeItem(constants.ACCESS_TOKEN);
          if (isCheckToken) {
            redirectToAppWithAuthParams({ noValidAccessToken: true });
          } else {
            navigate(routes.login);
          }
        }
      });
    } else if (isCheckToken) {
      Log.debug("redirect back without valid token");
      redirectToAppWithAuthParams({ noValidAccessToken: true });
    } else {
      Log.debug("redirect to login");
      navigate(routes.login);
    }
  };

  const registerAndJoinTeam = (joinTeamInfo: IJoinTeamInfo, isAuthed: boolean) => {
    return new Promise<unknown>((resolve) => {
      setJoinTeamInfo(joinTeamInfo);
      const joinTeamInfoStringfy = JSON.stringify(joinTeamInfo);
      sessionStorage.setItem(constants.JOIN_TEAM_INFO, joinTeamInfoStringfy);
      const email = joinTeamInfo.email;
      setEmail(email);
      if (isAuthed) {
        Log.debug("[USI] registerAndJoinTeam auth success");
        joinTeamWithTeamId(joinTeamInfo).then((hasJoined) => {
          resolve(hasJoined);
        });
      } else {
        Log.debug("[USI] auth failed and re-login ");
        signInWithEmail(email).then((res) => {
          resolve(res);
        });
      }
    });
  };

  const logout = () => {
    Log.debug("Vibe-One logout");
    localStorage.removeItem(constants.ACCESS_TOKEN);
    // localStorage.removeItem(constants.TEAM_ID);
    // message.success("You have successfully signed out of your account.");
    if (signInFrom === "one") {
      Log.debug("logout redirectUrl: ", redirectUrl);
      window.location.replace(redirectUrl);
    } else {
      navigate(routes.login);
    }
  };

  const authState = {
    SSOUrl,
    checkCode,
    email,
    username,
    invalidTypes,
  };

  const contextValue = {
    authState,
    email,
    isLoading,
    signInFrom,
    redirectUrl,
    teamInfoList,
    redirectToAppWithAuthParams,
    authWithLocalAccessToken,
    authWithToken,
    authUser,
    signInWithEmail,
    registerApproval,
    emailApproval,
    emailTvApproval,
    createWorkspace,
    registerAndJoinTeam,
    logout,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}
