import React, { useEffect, useMemo, useState } from "react";

import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import message from "@/components/CustomMessage";
import { useAuth } from "@/hooks";
import type { IJoinTeamInfo } from "@/hooks";
import { getQueryValueByKeys } from "@/utils";
import Log from "@/utils/Log";
import constants from "@/constants";

export const EmailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3337 13.75C12.4132 13.75 11.667 14.4962 11.667 15.4167V24.5833C11.667 25.5038 12.4132 26.25 13.3337 26.25H26.667C27.5875 26.25 28.3337 25.5038 28.3337 24.5833V15.4167C28.3337 14.4962 27.5875 13.75 26.667 13.75H13.3337ZM13.3337 15.4167H26.667V16.0544L20.5781 20.1136C20.2283 20.3469 19.7724 20.3469 19.4225 20.1136L13.3337 16.0544V15.4167ZM13.3337 17.5567V24.5833H26.667V17.5567L21.2715 21.1537C20.5018 21.6669 19.4989 21.6669 18.7291 21.1537L13.3337 17.5567Z"
      fill="#727272"
    />
  </svg>
);

const RegisterByInvitation = () => {
  const [isAuthed, setAuthed] = useState(false);
  const auth = useAuth();
  const email = getQueryValueByKeys("email");
  const code = getQueryValueByKeys("code");
  const isRegister = getQueryValueByKeys("register") === "true";
  const teamId = getQueryValueByKeys("team_id");
  const teamName = getQueryValueByKeys("team_name");

  const noEnoughData = useMemo(() => !email || !code || !teamId, [email, code, teamId]);

  const title = isRegister
    ? `Create a Vibe account to join "${teamName}"`
    : `Sign in to join "${teamName}"`;

  const handleCreateByInvitation = () => {
    if (noEnoughData) return;
    const joinTeamInfo: IJoinTeamInfo = { email, code, teamId, teamName };
    auth.registerAndJoinTeam(joinTeamInfo, isAuthed);
  };

  useEffect(() => {
    if (noEnoughData) {
      Log.error("[USI] registerAndJoinTeam failed: missing infos");
      message.error("Join team workspace failed, please retry!");
      return;
    }
    sessionStorage.setItem(constants.HIDE_ERROR_MSG, "joinTeamTryAuth");
    const localAccessToken = localStorage.getItem(constants.ACCESS_TOKEN);
    if (!localAccessToken) return;
    auth.authWithToken(localAccessToken).then((res) => {
      const isAuthed = res?.user?.email === email;
      setAuthed(isAuthed);
      Log.debug(`registerAndJoinTeam ${isAuthed ? "isAuthed" : "notAuthed"}`);
    });
  }, []);

  return (
    <CustomLayout>
      <VibeTitle title={title} />
      <div className="input-component input-wrapper disabled">
        <EmailIcon />
        <input disabled placeholder={email} />
      </div>
      <button className="button-primary-360" onClick={handleCreateByInvitation}>
        Continue
      </button>
    </CustomLayout>
  );
};

export default RegisterByInvitation;
