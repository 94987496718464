import React, { useEffect, useMemo, useRef, useState } from "react";

import CustomLayout from "@/components/CustomLayout";
import Link from "@/components/CustomLink";
import Input from "@/components/Input";
import SSOLoginBtns from "@/components/SSOLoginBtns";
import VibeTitle from "@/components/VibeTitle";

import { useAuth } from "@/hooks";
import constants, { routes } from "@/constants";
import { getQueryValueByKeys } from "@/utils";
import Log from "@/utils/Log";

const RegisterLink = () => (
  <div className="bottom-link-wrapper">
    <span className="title">{"Don't have an account?"}</span>
    <Link className="title" to={routes.register}>
      {"Sign up now"}
    </Link>
  </div>
);

const WarningIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#FCBD1B" />
    <path
      d="M8.80204 5.83485C8.77366 5.15367 9.31823 4.58594 10 4.58594C10.6818 4.58594 11.2263 5.15367 11.198 5.83485L10.9765 11.1492C10.9547 11.6728 10.524 12.0859 10 12.0859C9.47604 12.0859 9.04528 11.6728 9.02347 11.1492L8.80204 5.83485Z"
      fill="white"
    />
    <circle cx="10" cy="14.1719" r="1.25" fill="white" />
  </svg>
);

const InvalidAdminAccountHints = ({ openCreatAccess }: { openCreatAccess?: boolean }) => (
  <div className="invalid-admin-wrapper">
    <WarningIcon />
    <p>
      {"Sorry, we couldn't sign you in to your account. The Vibe Admin view is only available for "}
      <em>Workspace Owners</em> or <em>Workspace Admin</em> accounts.
      {openCreatAccess && " You will need to first create a workspace."}
    </p>
  </div>
);

const adminInvalidHints = (type: string) => {
  switch (type) {
    case constants.INVALID_ACCOUNT:
      return <InvalidAdminAccountHints />;
    case constants.NO_AVAILABLE_WORKSPACE:
      return (
        <>
          <InvalidAdminAccountHints openCreatAccess />
          <Link className="invalid-hints-link" to={routes.createWorkspace}>
            {"Create a team workspace"}
          </Link>
        </>
      );
    default:
      return null;
  }
};

const EmailLogin = ({ invalidTypes }: { invalidTypes: string }) => {
  const [isValid, setValid] = useState<boolean>(false);
  const [showInvalid, setShowInvalid] = useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const auth = useAuth();
  const signInFrom = auth.signInFrom as SignInApps;

  const isAdmin = signInFrom === "admin";
  const prefilledEmail = getQueryValueByKeys(constants.EMAIL_KEY) || auth?.authState?.email;

  function handleValidationChange(isValid: boolean) {
    setValid(isValid);
  }

  function handleSignClick() {
    setShowInvalid(true);
    if (isValid) {
      const email = emailInputRef?.current?.value || "";
      auth.signInWithEmail(email);
    } else {
      emailInputRef.current?.focus();
    }
  }

  useEffect(() => {
    emailInputRef.current?.focus();
  }, []);

  return (
    <>
      <VibeTitle title="Sign in to your Vibe account" />
      <Input
        type="email"
        inputRef={emailInputRef}
        realTimeInvalidStyle={showInvalid}
        prefilled={prefilledEmail}
        onKeyDown={handleSignClick}
        onValidationChange={handleValidationChange}
      />
      <button className="button-primary-360" onClick={handleSignClick}>
        Continue
      </button>
      {!invalidTypes ? <RegisterLink /> : null}
      {isAdmin && adminInvalidHints(invalidTypes)}
    </>
  );
};

const Login = () => {
  const auth = useAuth();
  const invalidTypes = auth?.authState?.invalidTypes || "";

  const loginFooter = useMemo(() => {
    Log.debug("invalidTypes: ", invalidTypes);
    return !invalidTypes ? SSOLoginBtns() : null;
  }, [invalidTypes]);

  useEffect(() => {
    auth.authUser();
  }, []);

  return (
    <CustomLayout contentClassName="login-page" footer={loginFooter}>
      <EmailLogin invalidTypes={invalidTypes} />
    </CustomLayout>
  );
};

export default Login;
